html, body {
  min-height: 100%;
  min-height: -webkit-fill-available;
  background-color: #f5f5f5;
  padding: 0;
  margin: 0;
  font-family: Kanit, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    z-index: -1;
}

#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

h1,h2,h3,h4,h5,h6,p {
  font-family: Kanit, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.bg-white {
  background-color: white !important;
}

.list-content {
  list-style-type: disc;
}

.list-content > li {
  margin-left: 1.3em;
}

.topic-list-content {
  list-style-type: decimal;
}

.topic-list-content > li {
  margin-left: 1.3em;
}